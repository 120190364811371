export default {
  tooltip: {
    trigger: 'axis',
    transitionDuration: 0
  },
  legend: {
    data: []
  },
  grid: {
    top: '8%',
    left: '0%',
    right: '5%',
    bottom: '5%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLine: {
      lineStyle: {
        color: '#000',
        width: 0.1
      }
    },
    axisLabel: {
      interval: 0
    }
  },
  yAxis: [
    // 0
    {
      type: 'value',
      axisLabel: {
        formatter: '{value}',
        show: true
      },
      position: '',
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      splitLine: {
        show: true
      }
    },
    // 1
    {
      type: 'value',
      position: '',
      axisLine: {
        lineStyle: {
          color: '#fff',
          width: 0.1
        }
      },
      axisTick: {
        show: true
      },
      splitLine: {
        show: false
      },
      axisLabel: {
        show: false
      }
    }
  ],
  series: [
    {
      name: '',
      type: 'line',
      stack: 'Total',
      areaStyle: {
        normal: {
          color: 'rgba(51, 102, 255, 0.1)'
          // color: {
          //   type: 'linear',
          //   x: 0,
          //   y: 0,
          //   x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
          //   y2: 1,
          //   colorStops: [{
          //     offset: 0,
          //     color: '#EF6666' // 0% 处的颜色
          //   }, {
          //     offset: 1,
          //     color: '#fff' // 100% 处的颜色
          //   }],
          //   globalCoord: false // 缺省为 false
          // }
        }
      },
      smooth: true,
      yAxisIndex: 0,
      data: [],
      symbol: 'circle', // 设定为实心点
      symbolSize: 0, // 设定实心点的大小
      itemStyle: {
        normal: {
          color: 'rgba(51, 102, 255, 1)',
          lineStyle: {
            color: 'rgba(51, 102, 255, 1)',
            width: 5
          }
        }
      }
    },
    // DAU
    {
      name: '',
      type: 'line',
      areaStyle: {
        normal: {
          color: 'rgba(38, 228, 153, 0.1)'
          // color: {
          //   type: 'linear',
          //   x: 0,
          //   y: 0,
          //   x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
          //   y2: 1,
          //   colorStops: [{
          //     offset: 0,
          //     color: '#4666d0' // 0% 处的颜色
          //   }, {
          //     offset: 1,
          //     color: '#fff' // 100% 处的颜色
          //   }],
          //   globalCoord: false // 缺省为 false
          // }
        }
      },
      smooth: true,
      yAxisIndex: 1,
      data: [],
      symbol: 'circle', // 设定为实心点
      symbolSize: 0, // 设定实心点的大小
      itemStyle: {
        normal: {
          color: 'rgba(38, 228, 153, 1)',
          lineStyle: {
            color: 'rgba(38, 228, 153, 1)',
            width: 5
          }
        }
      }
    }
  ]
}
