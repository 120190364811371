<template>
  <div class="control-container">
    <div class="indicator">
      <div class="text-right">
        <a-radio-group v-model="dayValue" @change="handleChangeDayValue">
          <a-radio-button value="today">今日</a-radio-button>
          <a-radio-button value="yesterday"> 昨日</a-radio-button>
          <a-radio-button value="7th"> 7日</a-radio-button>
          <a-radio-button value="30th">30日 </a-radio-button>
        </a-radio-group>
      </div>
      <div class="launchsituationBox">
        <div class="itemBox" style="border-left: none">
          <div class="title">曝光量</div>
          <div style="display: flex; justify-content: space-between">
            <div class="number">{{ dayItem.impress || '--' }}</div>
            <div class="propor-title">
              <span>环比 </span>
              <span
                :class="{ propor: true, increase: dayItem.impressRatio < 0, decrease: dayItem.impressRatio > 0 }"
              >{{ dayItem.impressRatio > 0 ? '+' + dayItem.impressRatio : dayItem.impressRatio || 0 + '' }}%</span
              >
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="title">点击量</div>
          <div style="display: flex; justify-content: space-between">
            <div class="number">{{ dayItem.click || '--' }}</div>
            <div class="propor-title">
              <span>环比 </span>
              <span
                :class="{ propor: true, increase: dayItem.clickRatio < 0, decrease: dayItem.clickRatio > 0 }"
              >{{ dayItem.clickRatio > 0 ? '+' + dayItem.clickRatio : dayItem.clickRatio || 0 + '' }}%</span
              >
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="title">eCPM</div>
          <div style="display: flex; justify-content: space-between">
            <div class="number">{{ dayItem.ecpm || '--' }}</div>
            <div class="propor-title">
              <span>环比 </span>
              <span
                :class="{ propor: true, increase: dayItem.ecpmRatio < 0, decrease: dayItem.ecpmRatio > 0 }"
              >{{ dayItem.ecpmRatio > 0 ? '+' + dayItem.ecpmRatio : dayItem.ecpmRatio || 0 + '' }}%</span
              >
            </div>
          </div>
        </div>
        <div class="itemBox">
          <div class="title">花费 (元)</div>
          <div style="display: flex; justify-content: space-between">
            <div class="number">{{ dayItem.cost || '--' }}</div>
            <div class="propor-title">
              <span>环比 </span>
              <span
                :class="{ propor: true, increase: dayItem.costRatio < 0, decrease: dayItem.costRatio > 0 }"
              >{{ dayItem.costRatio > 0 ? '+' + dayItem.costRatio : dayItem.costRatio || 0 + '' }}%</span
              >
            </div>
          </div>
        </div>
      </div>
      <a-select size="small" :value="i1" style="width: 120px; margin-right: 40px" @change="selectIndicator($event, 1)">
        <template>
          <a-select-option v-for="(item, i) in targetList" :key="i" :disabled="i2 === item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </template>
      </a-select>
      <a-select size="small" :value="i2" style="width: 120px" @change="selectIndicator($event, 2)">
        <template>
          <a-select-option v-for="(item, i) in targetList" :key="i" :disabled="i1 === item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </template>
      </a-select>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'time',
    event: 'changeTime'
  },
  data () {
    return {
      date: [],
      dayValue: 'today',
      targetList: [
        { label: '曝光', value: 'impress', color: '#ffab31' },
        { label: '点击', value: 'click', color: 'rgb(76, 180, 231)' },
        { label: '点击率', value: 'clickRate', color: '#de7af9' },
        { label: 'eCPM', value: 'ecpm', color: 'rgb(0, 118, 143)' },
        { label: '消耗', value: 'cost', color: '#ff746c' }
      ]
    }
  },
  props: {
    time: {
      type: Array,
      default: () => []
    },
    indicator1: {
      type: String,
      default: ''
    },
    indicator2: {
      type: String,
      default: ''
    },
    dayItem: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    i1 () {
      return this.indicator1
    },
    i2 () {
      return this.indicator2
    }
  },
  watch: {
    time: {
      handler (val) {
        this.date = [...val]
      }
    }
  },
  mounted () {},
  methods: {
    getColor (val) {
      return this.targetList.filter((item) => item.value === val)[0].color
    },
    handleChangeDayValue (e) {
      this.$emit('changeDay', e.target.value)
    },
    selectIndicator (val, index) {
      this.$emit('changeIndicator', [val, index])
    }
  }
}
</script>

<style lang="less" scoped>
.text-right {
  text-align: right;
}
.propor-title {
  font-size: 13px;
  color: #b3b3b3;
  line-height: 2;
}
.increase {
  color: #f02388;
}
.decrease {
  color: #27ca8a;
}
::v-deep.ant-radio-button-wrapper-checked {
  color: #456bff;
  background: fade(@primary-color, 15%);
  border: 1px solid #456bff;
}

.control-container {
  vertical-align: top;
  border-radius: 5px;
  .launchsituationBox {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border: 1px solid #dae2ff;
    margin: 20px 0;
    min-width: 800px;
    background: #f9faff;
    border-radius: 5px;
    .itemBox {
      flex: 1;
      padding-right: 30px;
      padding-left: 30px;
      border-left: 1px solid #dae2ff;
    }
    .title {
      color: #999;
      padding-top: 10px;
    }
    .number {
      font-size: 20px;
      font-weight: 600;
      color: #000;
    }
  }
  ::v-deep .ant-select.ant-select-sm .ant-select-selection {
    height: 36px;
    line-height: 36px;
    width: 150px;
  }
  ::v-deep .ant-select-selection__rendered {
    line-height: 36px;
  }
}
</style>
