<template>
  <div class="data-indicator-container">
    <div>
      <Control
        :indicator1="indicator1"
        :indicator2="indicator2"
        @changeDay="changeDay"
        :dayItem="dayItem"
        @changeIndicator="changeIndicator"
      />
      <HomeTitle title="投放情况" borderColor="#91CC75" class="homeTitle" />
    </div>
    <a-spin :style="{ flexGrow: '1' }" :spinning="spinning">
      <div ref="chart" class="chart" style="width: 100%; height: 100%"></div>
    </a-spin>
  </div>
</template>

<script>
import HomeTitle from '../components/homeTitle'
import mixDate from '@/mixins/initDate'
import Control from './control'
import { launchsituation } from '@/api/agentadvertiser'
import options from './option'
import * as echarts from 'echarts'
export default {
  components: { HomeTitle, Control },
  mixins: [mixDate],
  data () {
    return {
      spinning: false,
      query: {
        // dateEnd: '',
        // dateStart: ''
      },
      indicator1: 'click',
      indicator2: 'impress',
      dayItem: {},
      datas: {
        lable: [],
        list: []
      },
      tempdatas: {
        lable: [],
        list: [
          {
            check: false,
            data: [],
            field: 'impress',
            name: '曝光'
          },
          {
            check: false,
            data: [],
            field: 'click',
            name: '点击'
          },
          {
            check: false,
            data: [],
            field: 'clickRate',
            name: '点击率'
          },
          {
            check: false,
            data: [],
            field: 'ecpm',
            name: 'eCPM'
          },
          {
            check: false,
            data: [],
            field: 'cost',
            name: '消耗'
          }
        ]
      },
      options: {},
      dayValue: '',
      myEcharts: null,
      colorList: {
        impress: '#ffab31',
        click: 'rgb(76, 180, 231)',
        clickRate: '#de7af9',
        ecpm: 'rgb(0, 118, 143)',
        cost: '#ff746c'
      }
    }
  },
  mounted () {
    this.options = JSON.parse(JSON.stringify(options))
    this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'canvas' })
    this.initChart()
    window.addEventListener('resize', this.getSize)
    const { endDate, startDate } = this.initDate(0, 0)
    // this.rangePickerDate = [start, end]
    this.query.endDate = endDate
    this.query.startDate = startDate
    this.getData()
  },
  destroyed () {
    this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        this.myEcharts.resize()
      }
    },
    initChart () {
      this.myEcharts.setOption(this.options)
    },
    changeDate (datas) {
      this.query.dateEnd = datas[1]
      this.query.dateStart = datas[0]
      this.getData()
    },
    async getData () {
      this.spinning = true
      const resp = await launchsituation(this.query)
      if (resp.code === 200 && resp.data) {
        const { impress = 0, click, ecpm, cost, impressRatio, ecpmRatio, costRatio, clickRatio } = resp.data
        this.dayItem = { impress, click, ecpm, cost, impressRatio, ecpmRatio, costRatio, clickRatio }
        const tempData = resp.data.detailedData || []
        this.tempdatas.lable = []
        this.tempdatas.list.forEach((ele) => {
          ele.data = []
        })
        tempData.map((item) => {
          this.dayValue === '7th' || this.dayValue === '30th'
            ? this.tempdatas.lable.push(item.date)
            : this.tempdatas.lable.push(item.hour)
          this.tempdatas.list.forEach((ele) => {
            if (ele.field === 'impress') {
              ele.data.push(item.impress)
            } else if (ele.field === 'click') {
              ele.data.push(item.click)
            } else if (ele.field === 'clickRate') {
              ele.data.push(item.clickRate)
            } else if (ele.field === 'ecpm') {
              ele.data.push(item.ecpm)
            } else if (ele.field === 'cost') {
              ele.data.push(item.cost)
            }
          })
        })
        this.datas = this.tempdatas
        this.setOptions()
      }
      this.spinning = false
    },
    changeIndicator (event) {
      if (event[1] === 1) {
        this.indicator1 = event[0]
      } else {
        this.indicator2 = event[0]
      }
      this.getData()
    },
    changeDay (value) {
      this.dayValue = value
      if (value === 'today') {
        const { endDate, startDate } = this.initDate(0, 0)
        this.query.endDate = endDate
        this.query.startDate = startDate
      } else if (value === 'yesterday') {
        const { endDate, startDate } = this.initDate(1, 1)
        this.query.endDate = endDate
        this.query.startDate = startDate
      } else if (value === '7th') {
        const { endDate, startDate } = this.initDate(7, 0)
        this.query.endDate = endDate
        this.query.startDate = startDate
      } else if (value === '30th') {
        const { endDate, startDate } = this.initDate(30, 0)
        this.query.endDate = endDate
        this.query.startDate = startDate
      }
      this.getData()
    },
    setOptions () {
      this.options.xAxis.data = this.datas.lable
      if (this.dayValue === '30th') this.options.xAxis.axisLabel.interval = Math.floor(this.datas.lable.length / 7)
      const list = this.datas.list
      const data1 = list.filter((item) => item.field === this.indicator1)[0] || []
      this.options.series[0].data = data1.data || []
      this.options.series[0].name = data1.name || ''
      // this.options.series[0].itemStyle.normal.color = this.colorList[this.indicator1]
      // this.options.series[0].itemStyle.normal.lineStyle.color = this.colorList[this.indicator1]
      // this.options.series[0].areaStyle.normal.color.colorStops = [{
      //   offset: 0,
      //   color: this.colorList[this.indicator1] // 0% 处的颜色
      // }, {
      //   offset: 1,
      //   color: '#fff' // 100% 处的颜色
      // }]
      this.options.legend.data[0] = data1.name
      const data2 = list.filter((item) => item.field === this.indicator2)[0] || []
      this.options.series[1].data = data2.data || []
      this.options.legend.data[1] = data2.name || ''
      // this.options.series[1].itemStyle.normal.color = this.colorList[this.indicator2]
      // this.options.series[1].itemStyle.normal.lineStyle.color = this.colorList[this.indicator2]
      // this.options.series[1].areaStyle.normal.color.colorStops = [{
      //   offset: 0,
      //   color: this.colorList[this.indicator2] // 0% 处的颜色
      // }, {
      //   offset: 1,
      //   color: '#fff' // 100% 处的颜色
      // }]
      this.options.series[1].name = data2.name
      this.initChart()
    }
  }
}
</script>

<style lang="less" scoped>
.data-indicator-container {
  background: #fff;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  /deep/.ant-spin-container {
    position: relative;
    height: 100%;
    .chart {
      overflow: hidden;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
    }
  }
  .homeTitle {
    position: absolute;
    top: 18px;
  }
}
</style>
